import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Table,
  CircularProgress,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  TablePagination,
} from "@mui/material";
import "./dashboard.css";
import { useNavigate } from "react-router-dom";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { GlobalStyles } from "@mui/system";
import GroupIcon from '@mui/icons-material/Group';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';


const Dashboard = () => {
  const [employeeCount, setEmployeeCount] = useState(0);
  const [presentsCount, setPresentsCount] = useState(0);
  const [attendance, setAttendance] = useState([]);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();

//dialog open code
  const [open, setOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const handleOpenModal = (employee) => {
    setSelectedEmployee(employee);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedEmployee(null);
  };




  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const todayDate = getTodayDate();

  useEffect(() => {
    const fetchEmployeeCount = async () => {
      try {
        const response = await fetch(
          ` https://login.hrbppayroll.com/hrbp/api/employee/count/by-organisation/1`
        );
        const data = await response.json();
        setEmployeeCount(data);
      } catch (error) {
        console.error("Failed to fetch employee count", error);
      }
    };

    fetchEmployeeCount();
  }, []);

  useEffect(() => {
    const fetchPresentsCount = async () => {
      try {
        const response = await fetch(
          ` https://login.hrbppayroll.com/hrbp/api/attendance/employees/count/${todayDate}`
        );
        const data = await response.json();
        setPresentsCount(data);
      } catch (error) {
        console.error("Failed to fetch presents count", error);
      }
    };

    fetchPresentsCount();
  }, [todayDate]);

  const totalAbsent = employeeCount - presentsCount;

  useEffect(() => {
    const fetchAllAttendance = async () => {
      try {
        setLoader(true);
        const response = await fetch(
          `https://login.hrbppayroll.com/hrbp/api/attendance/employees/all/${todayDate}`
        );
        const data = await response.json();

        const todayAttendace = data.filter(
          (employee) => employee.date === todayDate
        );
        const employeesWithImages = todayAttendace.map((employee) => ({
          ...employee,
          checkInPic: `data:image/jpeg;base64,${employee.checkInPic}`,
          checkOutPic: `data:image/jpeg;base64,${employee.checkOutPic}`
        }));

        // Sort employeesWithImages so newly added rows appear on top
        const sortedAttendance = employeesWithImages.reverse();

        setAttendance(sortedAttendance);
        console.log("I am 04-09-2024  Employee attendace :" + data.checkOut)
      } catch (error) {
        console.error("Failed to fetch attendance", error);
      } finally {
        setLoader(false);
      }
    };

    fetchAllAttendance();
  }, [todayDate]);

  //for punch in location
  const truncateString = (str, num) => {
    if (typeof str !== "string") {
      return "Punch In Location Not Found";
    }
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };
  //for punch out location
  const truncateString1 = (str, num) => {
    if (typeof str !== "string") {
      return "Punch Out Location Not Found";
    }
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };


  // const formatTimeFromArray = (timeArray) => {
  //   if (Array.isArray(timeArray) && timeArray.length >= 2) {
  //     const hours = String(timeArray[0]).padStart(2, "0");
  //     const minutes = String(timeArray[1]).padStart(2, "0");
  //     return `${hours}:${minutes}`;
  //   }
  //   return "N/A";
  // };

  // const attendanceNavigation = () => {
  //   navigate("/employeeAttendance");
  // };

  const employeeClick = () => {
    navigate("/employee");
  };

  const handleAbsents = () => {
    navigate("/absentsList");
  };

  const handlePresents = () => {
    navigate("/employeeAttendance");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box p={3} marginTop={9}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} onClick={employeeClick}>
          <Card
            sx={{
              height: "80px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
              cursor: 'pointer'
            }}
          >
            <CardContent sx={{ textAlign: "center", width: '100%' }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={{ xs: "row", sm: "row" }} // Keep row direction for all screens
              >
                <GroupIcon
                  sx={{
                    color: "#8000ff",
                    fontSize: { xs: 30, sm: 35 },
                    marginLeft: { xs: 2, sm: 2 }, // Margin for all screens
                    marginRight: { xs: 2 }, // Margin for mobile
                    // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                />
                <Box textAlign="right">
                  <Typography variant="h6" style={{ marginRight: "20px" }} sx={{ fontSize: { xs: 'body2', sm: 'h6' } }}>
                    Employees
                  </Typography>
                  <Typography variant="h4" style={{ marginRight: "50px" }} sx={{ fontSize: { xs: 'h6', sm: 'h4' } }}>
                    {employeeCount}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card
            sx={{
              height: "80px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
              cursor: 'pointer'
            }}
            onClick={handlePresents}
          >
            <CardContent sx={{ textAlign: "center", width: '100%' }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={{ xs: "row", sm: "row" }} // Keep row direction for all screens
              >
                <EmojiPeopleIcon
                  sx={{
                    color: "#4caf50",
                    fontSize: { xs: 30, sm: 35 },
                    marginLeft: { xs: 2, sm: 2 }, // Margin for all screens
                    marginRight: { xs: 2 }, // Margin for mobile
                    // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                />
                <Box textAlign="right">
                  <Typography variant="h6" style={{ marginRight: "20px" }} sx={{ fontSize: { xs: 'body2', sm: 'h6' } }}>
                    Presents
                  </Typography>
                  <Typography variant="h4" style={{ marginRight: "40px" }} sx={{ fontSize: { xs: 'h6', sm: 'h4' } }}>
                    {presentsCount}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card
            sx={{
              height: "80px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
              cursor: 'pointer'
            }}
            onClick={handleAbsents}
          >
            <CardContent sx={{ textAlign: "center", width: '100%' }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexDirection={{ xs: "row", sm: "row" }} // Keep row direction for all screens
              >
                <RemoveCircleOutlineIcon
                  sx={{
                    color: "#8000ff",
                    fontSize: { xs: 30, sm: 35 },
                    marginLeft: { xs: 2, sm: 2 }, // Margin for all screens
                    marginRight: { xs: 2 }, // Margin for mobile
                    // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",

                  }}
                />
                <Box textAlign="right">
                  <Typography variant="h6" style={{ marginRight: "20px" }} sx={{ fontSize: { xs: 'body2', sm: 'h6' } }}>
                    Absents
                  </Typography>
                  <Typography variant="h4" style={{ marginRight: "40px" }} sx={{ fontSize: { xs: 'h6', sm: 'h4' } }}>
                    {totalAbsent}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box mt={5}>
        <Card
          sx={{
            backgroundColor: "white",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Typography sx={{ fontSize: 18, padding: 1, textAlign: "center" }}>
            <strong style={{ padding: 12 }}>Daily Employee Attendance</strong>
          </Typography>
          <CardContent>



          <GlobalStyles
          styles={{
            '.custom-sidebar-scrollbar': {
              scrollbarWidth: 'thin', // For Firefox
              scrollbarColor: 'grey #e0e0e0',
            },
            '.custom-sidebar-scrollbar::-webkit-scrollbar': {
              width: '6px', // For WebKit-based browsers
            },
            '.custom-sidebar-scrollbar::-webkit-scrollbar-track': {
              background: '#f1f1f1', // Track color
            },
            '.custom-sidebar-scrollbar::-webkit-scrollbar-thumb': {
              backgroundColor: 'grey', // Thumb color
              borderRadius: '10px',
            },
            '.custom-sidebar-scrollbar::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
          }}
        />
            <TableContainer component={Paper} style={{ maxHeight: '500px', overflowY: 'auto' }} className="custom-sidebar-scrollbar">
              <Table stickyHeader>
                <TableHead className="tableHeader">
                  <TableRow>
                  <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                        // textTransform: "uppercase",
                      }}
                    >
                      S.No
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                        // textTransform: "uppercase",
                      }}
                    >
                      Thumbnail
                    </TableCell>
                   
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                        // textTransform: "uppercase",
                      }}
                    >
                      Employee Name
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                        // textTransform: "uppercase",
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                        // textTransform: "uppercase",
                      }}
                    >
                      Punch In Time
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                        // textTransform: "uppercase",
                      }}
                    >
                      Punch Out Time
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                        // textTransform: "uppercase",
                      }}
                    >
                      Location
                    </TableCell>
                    <TableCell
                      style={{
                        color: "white",
                        fontSize: "17px",
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                         backgroundColor:'#529CAD'
                        // textTransform: "uppercase",
                      }}
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>

               
                  <TableBody>
                    {loader ? (
                                                                    <TableRow>
                                                                      <TableCell colSpan={8} style={{ textAlign: "center", padding: "20px",fontSize:'15px' }}>
                                                                        Loading Data...
                                                                      </TableCell>
                                                                    </TableRow>
                                                                  ) : attendance.length === 0 ? (
                                                                    <TableRow>
                                                                      <TableCell colSpan={8} style={{ textAlign: "center", padding: "20px" }}>
                                                                        No data available
                                                                      </TableCell>
                                                                    </TableRow>
                                                                  ) : (
                    attendance
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((employee,index) => (
                        <TableRow key={employee.id}>
                          <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "15px",
                              textAlign: "center",
                              border: "1px solid black",
                            }}
                          >
                            {index+1}
                          </TableCell>
                          <TableCell
                            className="tableCell"
                            style={{
                              border: "1px solid black",
                              textAlign:'center',
                              fontWeight:"bold",
                              cursor:'pointer',
                            }}
                          >
                            <Avatar
                              alt={employee.firstName}
                              style={{marginLeft:'29%', marginBottom:'10px'}}
                              src={employee.checkInPic}
                              onClick={() => handleOpenModal(employee)}
                            
                            />
                            {employee.employeeId}
                          </TableCell>
                          <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "15px",
                              textAlign: "center",
                              border: "1px solid black",
                            }}
                          >
                            {employee.firstName} {employee.lastName}
                          </TableCell>
                          <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "15px",
                              textAlign: "center",
                              border: "1px solid black",
                            }}
                          >
                            {employee.date}
                          </TableCell>
                          <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "15px",
                              textAlign: "center",
                              border: "1px solid black",
                            }}
                          >
                            {/* {employee.checkIn} */}
                            {(employee.checkIn) || 'Not Punched in Yet'}
                          </TableCell>
                          <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "15px",
                              textAlign: "center",
                              border: "1px solid black",
                            }}
                          >
                            {/* {employee.checkOut}  */}
                            {(employee.checkOut) || 'Not Punched out Yet'}
                          </TableCell>

                          
                          {/* <TableCell
                            className="tableCell"
                            style={{
                              color: "black",
                              fontSize: "15px",
                              textAlign: "center",
                              border: "1px solid black",
                              padding: 0,  // Remove padding from the cell
                              verticalAlign: "top",  // Ensure content is aligned to the top
                              width:'30%'
                            }}
                          >
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                              width="100%" // Ensure the box takes up the full width
                            >
                              
                              <Box
                                style={{
                                  width: "100%",
                                  maxHeight: "50px", // Set a max height for the check-in section
                                  overflowY: "auto", // Enable vertical scrolling if content overflows
                                  padding: "8px 0 8px 0",
                                }}
                              >
                                <Typography variant="body2" style={{ fontSize: "15px" }}>
                                  {truncateString(employee.checkinLocation, 300)}
                                </Typography>
                              </Box>

                          
                              <Box
                                component="hr"
                                sx={{
                                  width: "100%",
                                  border: "none",           // Remove default border
                                  height: "1px",            // Set line height to 1px for thinness
                                  backgroundColor: "black", // Set the line color to black
                                  marginTop: 1.5,                // Remove any margin
                                }}
                              />

                              
                              <Box
                                style={{
                                  width: "100%",
                                  maxHeight: "50px", // Set a max height for the check-out section
                                  overflowY: "auto", // Enable vertical scrolling if content overflows
                                  padding: "8px 0",
                                }}
                              
                              >
                                <Typography variant="body2" style={{ fontSize: "15px" }}>
                                  {truncateString1(employee.checkoutLocation, 300)}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell> */}

                          <TableCell
  className="tableCell"
  style={{
    color: "black",
    fontSize: "15px",
    textAlign: "center",
    border: "1px solid black",
    padding: 0,  // Remove padding from the cell
    verticalAlign: "top",  // Ensure content is aligned to the top
    width: '30%'
  }}
>
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width="100%" // Ensure the box takes up the full width
  >
    {/* Check-in location */}
    <Box
      className="custom-sidebar-scrollbar" // Add the custom scrollbar class
      style={{
        width: "100%",
        maxHeight: "50px", // Set a max height for the check-in section
        overflowY: "auto", // Enable vertical scrolling if content overflows
        padding: "8px 0",
      }}
    >
      <Typography variant="body2" style={{ fontSize: "15px" }}>
        {truncateString(employee.checkinLocation, 300)}
      </Typography>
    </Box>

    {/* Divider Line */}
    <Box
      component="hr"
      sx={{
        width: "100%",
        border: "none", // Remove default border
        height: "1px", // Set line height to 1px for thinness
        backgroundColor: "black", // Set the line color to black
        marginTop: 1.5, // Remove any margin
      }}
    />

    {/* Check-out location */}
    <Box
      className="custom-sidebar-scrollbar" // Add the custom scrollbar class
      style={{
        width: "100%",
        maxHeight: "50px", // Set a max height for the check-out section
        overflowY: "auto", // Enable vertical scrolling if content overflows
        padding: "8px 0",
      }}
    >
      <Typography variant="body2" style={{ fontSize: "15px" }}>
        {truncateString1(employee.checkoutLocation, 300)}
      </Typography>
    </Box>
  </Box>
</TableCell>


                          <TableCell
                            className="tableCell"
                            style={{
                              color: employee.date === todayDate ? "green" : "red",fontSize: "17px",textAlign: "center",fontWeight: "bold",border: "1px solid black",
                            }}
                          >
                            {employee.date === todayDate ? "Present" : "Absent"}
                          </TableCell>
                        </TableRow>
                      ))
                      )}
                  </TableBody>

              </Table>
            </TableContainer>
           
          </CardContent>
        </Card>
      </Box>

      <Dialog open={open} onClose={handleCloseModal} maxWidth="md" fullWidth>
  <DialogTitle>
    {/* <strong style={{ fontSize: '20px' }}>Punch Details</strong> */}
    <strong style={{ fontSize: '20px' }}>Punch Details - 
      
    <strong style={{ color:'#7e31ce' }}> {selectedEmployee?.firstName} {selectedEmployee?.lastName} </strong>
    </strong>
    <IconButton
  aria-label="close"
  onClick={handleCloseModal}
  sx={{
    position: "absolute",
    right: 8,
    top: 8,
    color: "black", // Change the color to your desired value
  }}
>
  <CloseIcon />
</IconButton>

  </DialogTitle>
 
  <DialogContent >
    {selectedEmployee && (
      <Box
      
        gap={2}
      >
       
        {/* Punch In Details */}
        <Card sx={{ maxWidth: 900 }}>
        <strong style={{ fontSize: '15px',padding:'20px',color:'#0c8091' }}>Punch In Details</strong>
          <CardContent>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={2}
            >
              <Avatar
                alt="Punch In Photo"
                src={selectedEmployee.checkInPic}
                sx={{ width: 150, height: 150 }}
              />
              <Box>
                <Typography variant="h6" style={{ fontWeight: 'bold',paddingLeft:'25px',color:'#7e31ce' }}>Punch In Time: </Typography>
                <Typography variant="h6" style={{fontWeight:'bold',paddingLeft:'25px',color:'Green',paddingTop:5}}>
                {(selectedEmployee.checkIn) || 'N/A'}
                </Typography>
                <Typography variant="h6" style={{fontWeight:'bold',paddingLeft:'25px',color:'#7e31ce'}} mt={2}>
                  Punch In Address:
                </Typography>
                <Typography style={{paddingLeft:'25px',paddingTop:5}}>
                  {truncateString(selectedEmployee.checkinLocation, 300)}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>

        {/* Divider */}
        <Divider sx={{ width: "100%", marginY: 2 }} />

        {/* Punch Out Details */}
        <Card sx={{ maxWidth: 900, boxShadow: 1 }}>
        <strong style={{ fontSize: '15px',padding:'20px',color:'#0c8091' }}>Punch Out Details</strong>
          <CardContent>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={2}
            >
              <Avatar
                alt="Punch Out Photo"
                src={selectedEmployee.checkOutPic}
                sx={{ width: 150, height: 150 }}
              />
              <Box>
                <Typography variant="h6" style={{ fontWeight: 'bold',paddingLeft:'25px',color:'#7e31ce' }}>Punch Out Time: </Typography>
                <Typography variant="h6" style={{fontWeight:'bold',paddingLeft:'25px',color:'Green',paddingTop:5}}>
                {(selectedEmployee.checkOut) || 'N/A'}
                </Typography>
                <Typography variant="h6" style={{fontWeight:'bold',paddingLeft:'25px',color:'#7e31ce'}} mt={2}>
                  Punch Out Address:
                </Typography>
                <Typography style={{paddingLeft:'25px',paddingTop:5}}>
                  {truncateString(selectedEmployee.checkoutLocation, 300)}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    )}
  </DialogContent>
  
</Dialog>

    </Box>
  );
};

export default Dashboard;