import React, { useState, useEffect } from "react";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Box, Typography, IconButton} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PaymentIcon from "@mui/icons-material/Payment";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { useSidebarContext } from "./sidebarContext";
import { useCompany } from "../../CompanyContext/CompanyContext";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

const Item = ({ title, to, icon, selected, setSelected }) => {

  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <MenuItem
    active={isActive}
    onClick={() => setSelected(title)}
    icon={<Box sx={{ marginTop: "40px", color: isActive ? "black":"#225D6A" }}>{icon}</Box>}
    routerLink={<Link to={to} />}
    sx={{
      color: isActive ? "#0000FF" : "white", // Blue color when active, white otherwise
      fontWeight: isActive ? "bold" : "normal",
      "&:hover": {
        color: "red", // Hover color
        fontWeight: "bold",
      },
      backgroundColor: "transparent !important", // Ensures background doesn't change
    }}
  >
    <Typography
      sx={{
        fontWeight: "bold",
        fontSize: "1.1rem",
        marginTop: "30px",
        color: isActive ? "black" : "#3699AE", // Apply blue color to active text
      }}
    >
      {title}
    </Typography>
  </MenuItem>
  );
};

const MyProSidebar = () => {
  const [selected, setSelected] = useState("Dashboard");
  const { sidebarRTL, sidebarImage } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();
  const navigate = useNavigate();

  const { companyInfo, setCompanyInfo } = useCompany(); // Use context to get companyInfo and setCompanyInfo
  const [loading, setLoading] = useState(true); // Loader state


 
  //new added code
  useEffect(() => {
    const fetchCompanyInfo = async () => {
      try {
        const response = await fetch(`https://login.hrbppayroll.com/hrbp/api/organisation/1`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
  
        // Ensure data is not null and has the expected properties
        if (data && data.companyLogo && data.companyName) {
          setCompanyInfo({
            companyLogo: data.companyLogo,
            companyName: data.companyName,
          });
        } else {
          console.warn("Company logo or name is missing from the response data.");
          
        }
        setLoading(false); // Stop loading when data is fetched
      } catch (error) {
        console.error("Error fetching company info:", error);
        setLoading(false); // Stop loading even if there's an error
      }
    };
  
    fetchCompanyInfo();
  }, [setCompanyInfo]);


 

  const menuItems = [
    <Item
      title="Dashboard"
      to="/dashboard"
      icon={<HomeOutlinedIcon />}
      selected={selected}
      setSelected={setSelected}
    />,
    <Item
      title="Employees"
      to="/employee"
      icon={<GroupAddIcon />}
      selected={selected}
      setSelected={setSelected}
    />,
    <Item
      title="Attendance"
      to="/employeeAttendance"
      icon={<FingerprintIcon />}
      selected={selected}
      setSelected={setSelected}
    />,
    <Item
      title="Leave Management"
      to="/leaves"
      icon={<EventNoteIcon />}
      selected={selected}
      setSelected={setSelected}
    />,
    
    <Item
    title="Holiday Calendar"
    to="/HolidayCalendar"
    icon={<CalendarMonthIcon />}
    selected={selected}
    setSelected={setSelected}
  />,

    <Item
      title="Payroll"
      to="/paySlip"
      icon={<PaymentIcon />}
      selected={selected}
      setSelected={setSelected}
    />,
    <Item
    title="Reports"
    to="/SalaryReport"
    icon={<TextSnippetIcon />}
    selected={selected}
    setSelected={setSelected}
  />,
    
  ];

  return (
    <Box
      sx={{
        position: "sticky",
        display: "flex",
        height: "100vh",
        top: 0,
        bottom: 0,
        zIndex: 10000,
        "& .sidebar": {
          border: "none",
        },
        "& .menu-icon": {
          backgroundColor: "transparent !important",
        },
        "& .menu-item": {
          backgroundColor: "transparent !important",
          marginTop: "5px",
        },
        "& .menu-anchor": {
          color: "inherit !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-item:hover": {
          color: "white",
          fontWeight: "bold !important",
          backgroundColor: "transparent !important",
        },
        "& .menu-item.active": {
          color: `white !important`,
          fontWeight: "bold !important",
          backgroundColor: "transparent !important",
        },
      }}
    >
      <Sidebar
        breakPoint="md"
        rtl={sidebarRTL}
        backgroundColor="#BCE3E9"
        image={sidebarImage}
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        {/* Collapse Button Section */}
        <Box
          sx={{
            mb: "20px",
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            // p: "10px",
          
          }}
        >
          <IconButton
            onClick={broken ? () => toggleSidebar() : () => collapseSidebar()}
            sx={{ color: "#225D6A",marginRight:'22px'}}
          >
            {collapsed ? <MenuOutlinedIcon /> : <CloseOutlinedIcon />}
          </IconButton>
        </Box>

        {/* Company Logo Section */}
        {!collapsed && (
          <Box mb="25px" mt={2}>
            <Box display="flex" justifyContent="center" alignItems="center" ml="15px">
            {loading ? (
                <Typography variant="h4" >Loading...</Typography>  
              ) : (
              <img
                alt="company logo"
                width="100px"
                height="100px"
                src={`data:image/jpeg;base64,${companyInfo.companyLogo}`}
                style={{ cursor: "pointer", borderRadius: "50%" ,marginRight:'25px'}}
                onClick={() => navigate('/profile')}
              />
            )}
            </Box>
            {!loading && (
            <Box textAlign="center">
              <Typography variant="h3" color="white" fontWeight="bold" sx={{ m: "10px 0 0 0" , color:'#7e31ce',marginRight:'13px'} }>
                {companyInfo.companyName}
              </Typography>
            </Box>
            )}
          </Box>
        )}

        {/* Menu Items */}
        <Menu iconshape="square" style={{ flex: 1 }}>
          {menuItems.map((menuItem, index) => (
            <React.Fragment key={index}>{menuItem}</React.Fragment>
          ))}
        </Menu>

        {/* Footer */}
        {!collapsed && (
          <Box
            sx={{
              position: "relative",
              bottom: 0,
              width: "100%",
              p: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#BCE3E9",
              marginTop:'42%',
            }}
          >
            <img
              alt="footer logo"
              width="65px"
              height="58px"
              src="/assets/hrbplogo.png"
            />
            <Typography
              variant="body2"
              color="white"
              fontSize="1.3rem"
              fontWeight="bold"
              sx={{
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              HRBP
            </Typography>
          </Box>
        )}
      </Sidebar>
    </Box>
  );
};

export default MyProSidebar;
