import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  TextField,
  Button,
  Box
} from "@mui/material";

const shiftData = {
  A: [
    { id: "EMP001", name: "Alice Johnson", startTime: "06:00 AM", endTime: "02:00 PM" },
    { id: "EMP002", name: "Bob Smith", startTime: "06:30 AM", endTime: "02:30 PM" },
  ],
  B: [
    { id: "EMP003", name: "Charlie Davis", startTime: "02:00 PM", endTime: "10:00 PM" },
    { id: "EMP004", name: "Diana Lee", startTime: "02:30 PM", endTime: "10:30 PM" },
  ],
  C: [
    { id: "EMP005", name: "Ethan Wright", startTime: "10:00 PM", endTime: "06:00 AM" },
    { id: "EMP006", name: "Fiona Hill", startTime: "10:30 PM", endTime: "06:30 AM" },
  ],
};

const ReportTimings = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(shiftData);

  const handleSearch = () => {
    const query = searchQuery.toLowerCase();
    const updatedData = Object.keys(shiftData).reduce((acc, shift) => {
      acc[shift] = shiftData[shift].filter(
        (employee) =>
          employee.id.toLowerCase().includes(query) || employee.name.toLowerCase().includes(query)
      );
      return acc;
    }, {});
    setFilteredData(updatedData);
  };

  const handleReset = () => {
    setSearchQuery("");
    setFilteredData(shiftData);
  };

  return (
    <div style={{ padding: "20px" }}>
      {/* Overall Container Card */}
      <Card style={{ boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", borderRadius: "15px", padding: "20px" ,marginTop:'7%'}}>
        <Typography variant="h4" align="center" sx={{marginBottom:'25px',fontWeight:'bold'}}>
          Shift-Wise Employee Report
        </Typography>

        

        {/* Display Shift Cards */}
        <Grid container spacing={3}>
          {Object.entries(filteredData).map(([shift, employees]) => (
            <Grid item xs={12} sm={6} md={4} key={shift}>
              {/* Individual Shift Card */}
              <Card style={{ boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", borderRadius: "10px" }}>
                <CardContent>
                  <Typography variant="h6" component="div" style={{ marginBottom: "10px", textAlign: "center", color: "black",fontWeight:'bold' }}>
                    {shift} Shift
                  </Typography>

                  {/* Search Bar */}
                        {/* <Box  marginBottom="20px">
                        <TextField
                            label="Search by ID or Name"
                            variant="outlined"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            style={{ marginRight: "10px", width:'30%',minHeight:'29%'}}
                        />
                        <Button variant="contained" color="primary" onClick={handleSearch} style={{ marginRight: "10px" }}>
                            Search
                        </Button>
                        <Button variant="outlined" onClick={handleReset}>
                            Reset
                        </Button>
                        </Box> */}

                  {/* Table for Employees */}
                  <TableContainer component={Paper} style={{ borderRadius: "10px" }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow style={{ backgroundColor: "#1976d2" }}>
                          <TableCell style={{ color: "#fff", fontWeight: "bold" }}>Employee ID</TableCell>
                          <TableCell style={{ color: "#fff", fontWeight: "bold" }}>Name</TableCell>
                          <TableCell style={{ color: "#fff", fontWeight: "bold" }}>Start Time</TableCell>
                          <TableCell style={{ color: "#fff", fontWeight: "bold" }}>End Time</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {employees.length > 0 ? (
                          employees.map((employee) => (
                            <TableRow key={employee.id}>
                              <TableCell>{employee.id}</TableCell>
                              <TableCell>{employee.name}</TableCell>
                              <TableCell>{employee.startTime}</TableCell>
                              <TableCell>{employee.endTime}</TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              No matching records found.
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Card>
    </div>
  );
};

export default ReportTimings;
