import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();



  const handleGoBack = () => {
    navigate(-1); // Navigates back to the previous page
   
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      width:'100%',
      backgroundImage: 'url("/assets/404.png")', // Replace with the path to your image
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      color: 'white', // Text color on the background image
      textAlign: 'center',
    },
    button: {
        position: 'absolute', // Makes the button positionable within the container
        bottom: '20px', // Moves the button 20px from the bottom
        left: '50%', // Centers the button horizontally
        transform: 'translateX(-50%)', // Offsets the button by half its width to center it
        padding: '10px 20px',
        fontSize: '16px',
        backgroundColor: '#1976d2',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginLeft:'50px'
      },
   
  };

  return (
    <div style={styles.container}>
      {/* <h1 style={styles.h1}>404 - Not Found</h1>
      <p style={styles.p}>The page you are looking for does not exist.</p> */}
      <button style={styles.button} onClick={handleGoBack}>Go Back</button>
    </div>
  );
};

export default NotFoundPage;

