import React, { useState, useEffect } from "react";
import ExcelJS from "exceljs";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  Avatar,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
} from "@mui/material";
import { styled, useTheme } from "@mui/system";
import { GlobalStyles } from "@mui/system";
import ScreenShareOutlinedIcon from "@mui/icons-material/ScreenShareOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
const mockSalaryData = [
  {
    id: "EMP001",
    name: "Alice Johnson",
    department: "Finance",
    baseSalary: 50000,
    bonus: 5000,
    deductions: 2000,
    netSalary: 53000,
  },
  {
    id: "EMP002",
    name: "Bob Smith",
    department: "IT",
    baseSalary: 60000,
    bonus: 6000,
    deductions: 3000,
    netSalary: 63000,
  },
  {
    id: "EMP003",
    name: "Charlie Davis",
    department: "HR",
    baseSalary: 45000,
    bonus: 4000,
    deductions: 1500,
    netSalary: 47500,
  },
];

const SalaryReport = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(mockSalaryData);
  const [dateRange, setDateRange] = useState("today");
  const [loader, setLoader] = useState(false);
  const [empdata, setempdata] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState("lastmonth");

  const handleSearch = () => {
    const query = searchQuery.toLowerCase();
    const filtered = mockSalaryData.filter(
      (employee) =>
        employee.id.toLowerCase().includes(query) ||
        employee.name.toLowerCase().includes(query)
    );
    setFilteredData(filtered);
  };

  const handleReset = () => {
    setSearchQuery("");
    setFilteredData(mockSalaryData);
  };

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const todayDate = getTodayDate();
  const handleDateRangeChange = (e) => {
    const selectedValue = e.target.value;
    setDateRange(selectedValue);
  };
  const fetchAllAttendance = async () => {
    try {
      setLoader(true); // Start loading before fetching

      if (!selectedMonth) return;

      const formattedMonth = format(selectedMonth, "MM"); // Format month as "01"
      const formattedYear = format(selectedMonth, "yyyy"); // Format year as "2024"

      const endpoint = `https://login.hrbppayroll.com/hrbp/api/payslip/getPayslipById/preview/${formattedMonth}/${formattedYear}`;

      console.log("Endpoint:", endpoint);

      const response = await fetch(endpoint);
      const data = await response.json();

      console.log("Fetched Data:", data);

      setempdata(data);
    } catch (error) {
      console.error("Failed to fetch attendance", error);
    } finally {
      setLoader(false); // Hide loader
    }
  };

  useEffect(() => {
    if (selectedMonth) {
      fetchAllAttendance();
    }
  }, [selectedMonth]);

  const handlePeriod = async (currentMonth, currentYear) => {
    try {
      const response = await fetch(
        `https://login.hrbppayroll.com/hrbp/api/payslip/getPayslipById/preview/${currentMonth}/${currentYear}/${selectedPeriod}`,
        {
          method: "GET",
          // headers: {
          //   "Content-Type": "application/json",
          // },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setempdata(data);
        // Handle the data as needed (e.g., download or display it)
      } else {
        console.error("API Error:", response.statusText);
        alert("Failed to export data.");
      }
    } catch (error) {
      console.error("Fetch Error:", error);
      alert("An error occurred during export.");
    }
  };

  useEffect(() => {
    // Get the current month and year
    const today = new Date();
    const currentMonth = today.getMonth() + 1; // Months are 0-indexed
    const currentYear = today.getFullYear();

    // Call the handlePeriod function with current month and year
    handlePeriod(currentMonth, currentYear);
  }, []);

  const formatDate = (dateString) => {
    // Assuming dateString is like "2024121" in format YYYYMMDD
    const date = dateString.toString();
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);
    
    // Format it as "YYYY-MM-DD"
    return `${year}-${month}-${day}`;
  };

  const handleExport = async () => {
    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Salary Report");

    // Define worksheet columns including "S.NO"
    worksheet.columns = [
      { header: "S.NO", key: "sno", width: 10 }, // Serial number column
      { header: "EMPLOYEE ID", key: "employeeId", width: 15 },
      { header: "EMPLOYEE NAME", key: "firstName", width: 20 },
      { header: "DATE", key: "generatedDate", width: 15 },
      { header: "NET PAY", key: "netPay", width: 15 },
      { header: "HRA", key: "hra", width: 20 },
      { header: "DRA", key: "dra", width: 20 },
      { header: "TOTAL DEDUCATIONS", key: "totalDeduction", width: 30 },
      { header: "TAX", key: "tax", width: 15 },
    ];

    // Add rows to the worksheet with serial numbers (S.NO)
    empdata.forEach((employee, index) => {
      const formattedDate = employee.generatedDate
        ? employee.generatedDate.join("-")
        : ""; // Format date as YYYY-MM-DD

      worksheet.addRow({
        sno: index + 1, // Increment serial number for each row
        employeeId: employee.employeeId,
        firstName: `${employee.firstName} ${employee.lastName}`,
        generatedDate: formattedDate, // Use the formatted date
        netPay: employee.netPay,
        hra: employee.hra,
        dra: employee.dra,
        totalDeduction: employee.totalDeduction,
        tax: employee.tax,
      });
    });

    // Style each header cell
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true }; // Bold font
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "87CEEB" }, // Blue background for header cells
      };
      cell.alignment = { horizontal: "center", vertical: "center" }; // Center align header cells
    });

    // Align each data row to the center
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber > 1) {
        // Skip header row
        row.eachCell((cell) => {
          cell.alignment = { horizontal: "center", vertical: "center" }; // Center align data cells
        });
      }
    });

    // Calculate and add column-wise totals
    const totalRow = {
      sno: "", // No serial number for the totals row
      employeeId: "Totals", // Label for totals
      firstName: "", // Empty cells for non-numeric columns
      generatedDate: "",
      netPay: empdata.reduce(
        (sum, employee) => sum + (employee.netPay || 0),
        0
      ),
      hra: empdata.reduce((sum, employee) => sum + (employee.hra || 0), 0),
      dra: empdata.reduce((sum, employee) => sum + (employee.dra || 0), 0),
      totalDeduction: empdata.reduce(
        (sum, employee) => sum + (employee.totalDeduction || 0),
        0
      ),
      tax: empdata.reduce((sum, employee) => sum + (employee.tax || 0), 0),
    };

    // Add totals row to the worksheet
    const totalsRow = worksheet.addRow(totalRow);

    // Style the totals row
    totalsRow.eachCell((cell) => {
      cell.font = { bold: true }; // Make totals row bold
      cell.alignment = { horizontal: "center", vertical: "center" }; // Center align totals row
    });

    // Write the Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Employees_SalaryReport.xlsx";
    link.click();
  };

  const TableHeaderCell = styled(TableCell)(({ theme }) => ({
    color: "white",
    fontSize: "15px",
    textAlign: "center",
    fontWeight: "bold",
    border: "1px solid black",
  }));
  return (
    <Card
      style={{
        marginLeft: "20px",
        marginRight: "20px",
        marginTop: "7%",
        padding: "10px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      <CardContent>
        <Typography
          variant="h4"
          component="div"
          style={{
            marginBottom: "10px",
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "25px",
          }}
        >
          Salary Reports
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between", // Space between left and right
            alignItems: "center",
            marginBottom: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center", // Align items vertically in the center
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                views={["year", "month"]} // Show only year and month views
                label="Select Month"
                value={selectedMonth}
                onChange={(newValue) => setSelectedMonth(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    style={{ width: "280px", marginLeft: "15px" }}
                  />
                )}
              />
            </LocalizationProvider>

            {/* Period Dropdown */}
            <FormControl
              variant="outlined"
              style={{
                width: "200px",
                marginLeft: "20px", // Add space between date picker and select
              }}
            >
              <InputLabel>Select Period</InputLabel>
              <Select
                value={selectedPeriod}
                onChange={(e) => setSelectedPeriod(e.target.value)}
                label="Select Period"
              >
                <MenuItem value="lastmonth">Last Month</MenuItem>
                <MenuItem value="quarterly">Quarterly</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* Export Button on the right */}
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#7e31ce",
              marginRight: "20px", // Add margin to the right
              color: "#fff",
              "&:hover": { backgroundColor: "#7e31ce" },
            }}
            onClick={handleExport}
          >
            <ScreenShareOutlinedIcon sx={{ marginRight: 1 }} />
            Export
          </Button>
        </Box>

        <GlobalStyles
          styles={{
            ".custom-sidebar-scrollbar": {
              scrollbarWidth: "thin", // For Firefox
              scrollbarColor: "grey #e0e0e0",
            },
            ".custom-sidebar-scrollbar::-webkit-scrollbar": {
              width: "6px", // For WebKit-based browsers
            },
            ".custom-sidebar-scrollbar::-webkit-scrollbar-track": {
              background: "#f1f1f1", // Track color
            },
            ".custom-sidebar-scrollbar::-webkit-scrollbar-thumb": {
              backgroundColor: "grey", // Thumb color
              borderRadius: "10px",
            },
            ".custom-sidebar-scrollbar::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555",
            },
          }}
        />

        <TableContainer
          component={Paper}
          style={{ maxHeight: "500px", overflowY: "auto" }}
          className="custom-sidebar-scrollbar"
        >
          <Table stickyHeader>
            <TableHead className="tableHeader">
              <TableRow>
                <TableHeaderCell style={{ backgroundColor: "#529CAD" }}>
                  S.No
                </TableHeaderCell>
                <TableHeaderCell style={{ backgroundColor: "#529CAD" }}>
                  Thumbnail
                </TableHeaderCell>
                <TableHeaderCell style={{ backgroundColor: "#529CAD" }}>
                  Employee Name
                </TableHeaderCell>
                <TableHeaderCell style={{ backgroundColor: "#529CAD" }}>
                  Date
                </TableHeaderCell>
                <TableHeaderCell style={{ backgroundColor: "#529CAD" }}>
                  Net Pay
                </TableHeaderCell>
                <TableHeaderCell style={{ backgroundColor: "#529CAD" }}>
                  HRA
                </TableHeaderCell>
                <TableHeaderCell style={{ backgroundColor: "#529CAD" }}>
                  DRA
                </TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loader ? (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    style={{
                      textAlign: "center",
                      padding: "20px",
                      fontSize: "15px",
                    }}
                  >
                    Loading Data...
                  </TableCell>
                </TableRow>
              ) : empdata.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    style={{ textAlign: "center", padding: "20px" }}
                  >
                    No data available
                  </TableCell>
                </TableRow>
              ) : (
                empdata.map((employee, index) => (
                  <TableRow key={employee.id}>
                    <TableCell
                      className="tableCell"
                      style={{
                        color: "black",
                        fontSize: "15px",
                        textAlign: "center",
                        border: "1px solid black",
                      }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      className="tableCell"
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Avatar
                          alt={employee.firstName}
                          style={{ marginRight: "10px" }} // Space between avatar and text
                          src={`data:image/jpeg;base64,${employee.employee.profilePic}`}
                        />
                        <span>{employee.employeeId}</span>
                      </div>
                    </TableCell>

                    <TableCell
                      className="tableCell"
                      style={{
                        color: "black",
                        fontSize: "15px",
                        textAlign: "center",
                        border: "1px solid black",
                      }}
                    >
                      {employee.employee.firstName} {employee.employee.lastName}
                    </TableCell>
                    <TableCell
                      className="tableCell"
                      style={{
                        color: "black",
                        fontSize: "15px",
                        textAlign: "center",
                        border: "1px solid black",
                      }}
                    >
                      {employee.generatedDate}
                    </TableCell>
                    <TableCell
                      className="tableCell"
                      style={{
                        color: "black",
                        fontSize: "15px",
                        textAlign: "center",
                        border: "1px solid black",
                      }}
                    >
                      {/* {(employee.checkIn)} */}
                      {employee.netPay}
                    </TableCell>
                    <TableCell
                      className="tableCell"
                      style={{
                        color: "black",
                        fontSize: "15px",
                        textAlign: "center",
                        border: "1px solid black",
                      }}
                    >
                      {/* {(employee.checkIn)} */}
                      {employee.hra}
                    </TableCell>
                    <TableCell
                      className="tableCell"
                      style={{
                        color: "black",
                        fontSize: "15px",
                        textAlign: "center",
                        border: "1px solid black",
                      }}
                    >
                      {/* {(employee.checkIn)} */}
                      {employee.dra}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default SalaryReport;
