import React, { useState, useEffect } from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Button,
  CircularProgress,
  TablePagination,FormControl, InputLabel, MenuItem, Select,
  TextField,
} from "@mui/material";
import ScreenShareOutlinedIcon from "@mui/icons-material/ScreenShareOutlined";
import { styled, useTheme } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { GlobalStyles } from "@mui/system";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Container = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(9),
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
  fontSize: "1.5rem",
  textAlign: "center",
  fontWeight: "bold",
}));

const SignedInSection = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const SignedInTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "1.25rem",
}));

const EmployeeAttendance = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [attendance, setAttendance] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [open, setOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [dateRange, setDateRange] = useState("");

  const [openDialog, setOpenDialog] = useState(false); 
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");



  const handleOpenModal = (employee) => {
    setSelectedEmployee(employee);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedEmployee(null);
  };

  const TableHeaderCell = styled(TableCell)(({ theme }) => ({
    color: "white",
    fontSize: "15px",
    textAlign: "center",
    fontWeight: "bold",
    border: "1px solid black",
  }));

  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const todayDate = getTodayDate();

  //new addded code
  // useEffect(() => {
  //   const fetchAllAttendance = async () => {
  //     try {
  //       setLoader(true); // Show loader
  //       const response = await fetch(
  //         `https://login.hrbppayroll.com/hrbp/api/attendance/employees/all/${todayDate}`
  //       );
  //       const data = await response.json();

  //       const todayAttendace = data.filter(
  //         (employee) => employee.date === todayDate
  //       );
  //       const employeesWithImages = todayAttendace.map((employee) => ({
  //         ...employee,
  //         checkInPic: `data:image/jpeg;base64,${employee.checkInPic}`,
  //         checkOutPic: `data:image/jpeg;base64,${employee.checkOutPic}`
  //       }));

  //       // Sort employeesWithImages so newly added rows appear on top
  //       const sortedAttendance = employeesWithImages.reverse();

  //       setAttendance(sortedAttendance);
  //     } catch (error) {
  //       console.error("Failed to fetch attendance", error);
  //     } finally {
  //       setLoader(false); // Hide loader
  //     }
  //   };

  //   fetchAllAttendance();
  // }, [todayDate]);

  const handleDateRangeChange = (e) => {
    const selectedValue = e.target.value;
    setDateRange(selectedValue);
    
    if (selectedValue === "customDate") {
      setOpenDialog(true); // Open the dialog for custom date selection
    }

  };


  const handleGetButtonClick = () => {
    console.log("Start Date:", startDate, "End Date:", endDate);
    setOpenDialog(false);
    if (startDate && endDate) {
      setDateRange("customDate"); // Update state to trigger API call
      console.log("Date Range set to customDate");
    } else {
      console.error("Start Date or End Date is missing");
    }
  };
  

  const fetchAllAttendance = async () => {
    try {
      setLoader(true); // Start loading before fetching
      let endpoint = "";
  
      switch (dateRange) {
        case "today":
          endpoint = `https://login.hrbppayroll.com/hrbp/api/attendance/employees/all/${todayDate}`;
          break;
        case "yesterday":
          endpoint = `https://login.hrbppayroll.com/hrbp/api/attendance/employees/all/yesterday/${todayDate}`;
          break;
        case "lastWeek":
          endpoint = `https://login.hrbppayroll.com/hrbp/api/attendance/employees/all/LastWeek/${todayDate}`;
          break;
        case "CurrentMonth":
          endpoint = `https://login.hrbppayroll.com/hrbp/api/attendance/employees/all/currentMonth/${todayDate}`;
          break;
        case "lastMonth":
          endpoint = `https://login.hrbppayroll.com/hrbp/api/attendance/employees/all/LastMonth/${todayDate}`;
          break;
          case "customDate":
            if (startDate && endDate) {
              endpoint = `https://login.hrbppayroll.com/hrbp/api/attendance/employees/all/customDate/${startDate}/${endDate}`;
            } else {
              console.error("Custom date range is missing start or end date");
              return;
            }
            break;



        default:
          endpoint = `https://login.hrbppayroll.com/hrbp/api/attendance/employees/all/${todayDate}`;
          break;
      }
  
      console.log("Endpoint:", endpoint);
  
      const response = await fetch(endpoint);
      const data = await response.json();
  
      console.log("Fetched Data:", data);
  
      const employeesWithImages = data.map((employee) => ({
        ...employee,
        checkInPic: employee.checkInPic ? `data:image/jpeg;base64,${employee.checkInPic}` : null,
        checkOutPic: employee.checkOutPic ? `data:image/jpeg;base64,${employee.checkOutPic}` : null
      }));
  
      // Sort employeesWithImages so newly added rows appear on top
      const sortedAttendance = employeesWithImages.reverse();
  
      setAttendance(sortedAttendance);
    } catch (error) {
      console.error("Failed to fetch attendance", error);
    } finally {
      setLoader(false); // Hide loader
    }
  };
  
      
  useEffect(() => {
    // Fetch default attendance data on initial render
    if (!dateRange) {
      fetchAllAttendance();
    }
  }, []); // Run on initial render
    
      useEffect(() => {
        if (dateRange) {
          fetchAllAttendance();
        }
      }, [dateRange]);

      useEffect(() => {
        if (dateRange === "customDate" && startDate && endDate) {
          fetchAllAttendance();
        }
      }, [dateRange, startDate, endDate]);






  //for punch in location
  const truncateString = (str, num) => {
    if (typeof str !== "string") {
      return "Punch In Location Not Found";
    }
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  //for punch out location
  const truncateString1 = (str, num) => {
    if (typeof str !== "string") {
      return "Punch Out Location Not Found";
    }
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  // const formatTimeFromArray = (timeArray) => {
  //   if (Array.isArray(timeArray) && timeArray.length >= 2) {
  //     const hours = String(timeArray[0]).padStart(2, "0");
  //     const minutes = String(timeArray[1]).padStart(2, "0");
  //     return ` ${hours}:${minutes}`;
  //   }
  //   return "N/A";
  // };
  const formatTimeFromArray = (timeArray) => {
    if (Array.isArray(timeArray) && timeArray.length === 2) {
      const [hours, minutes] = timeArray;
      if (hours != null && minutes != null) {
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        return `${formattedHours}:${formattedMinutes}`;
      }
    }
    // Return "N/A" if the timeArray is invalid or doesn't contain two elements
    return "N/A";
  };
  


  const handleClickOpen = (employee) => {
    navigate(`/attendance/${employee.empId}`);
  };

  // const handleExport = async () => {
  //   const workbook = new ExcelJS.Workbook();
  //   const worksheet = workbook.addWorksheet("Attendance Data");

  //   worksheet.columns = [
  //     { header: "Employee ID", key: "employeeId", width: 15 },
  //     { header: "Employee Name", key: "firstName", width: 15 },

  //     { header: "Date", key: "date", width: 15 },
  //     { header: "Punch In Time", key: "checkIn", width: 20 },
  //     { header: "Punch Out Time", key: "checkOut", width: 20 },
  //     { header: "Location", key: "checkinLocation", width: 30 },
  //     { header: "Status", key: "status", width: 15 }, // The status column is the 6th column
  //   ];

  //   attendance.forEach((employee) => {
  //     worksheet.addRow({
  //       employeeId: employee.employeeId,
  //       firstName: `${employee.firstName} ${employee.lastName}`,

  //       date: employee.date,
  //       checkIn: employee.checkIn,
  //       checkOut: employee.checkOut,
  //       checkinLocation: employee.checkinLocation,
  //       status: employee.date === todayDate ? "Present" : "Absent",
  //     });
  //   });

  //   worksheet.getRow(1).font = { bold: true, color: "green" };
  //   worksheet.getRow(1).fill = {
  //     type: "pattern",
  //     pattern: "solid",
  //     fgColor: { argb: "FF0000FF" },
  //   };

  //   worksheet.eachRow((row, rowNumber) => {
  //     if (rowNumber > 1) {
  //       const statusCell = row.getCell(7); // Correcting to the 6th cell (status column)
  //       if (statusCell.value === "Present") {
  //         statusCell.fill = {
  //           type: "pattern",
  //           pattern: "solid",
  //           fgColor: { argb: "FF00FF00" }, // Green color for Present
  //         };
  //       } else {
  //         statusCell.fill = {
  //           type: "pattern",
  //           pattern: "solid",
  //           fgColor: { argb: "FFFF0000" }, // Red color for Absent
  //         };
  //       }
  //     }
  //   });

  //   const buffer = await workbook.xlsx.writeBuffer();
  //   saveAs(new Blob([buffer]), "Employee_Attendance.xlsx");
  // };

  // const handleExport = async () => {
  //   const workbook = new ExcelJS.Workbook();
  //   const worksheet = workbook.addWorksheet("Attendance Data");
  
  //   worksheet.columns = [
  //     { header: "Employee ID", key: "employeeId", width: 15 },
  //     { header: "Employee Name", key: "firstName", width: 15 },
  //     { header: "Date", key: "date", width: 15 },
  //     { header: "Punch In Time", key: "checkIn", width: 20 },
  //     { header: "Punch Out Time", key: "checkOut", width: 20 },
  //     { header: "Location", key: "checkinLocation", width: 30 },
  //     { header: "Status", key: "status", width: 15 },
  //   ];
  
  //   attendance.forEach((employee) => {
  //     const checkIn = employee.checkIn || "Not Punched in Yet";
  //     const checkOut = employee.checkOut || "Not Punched out Yet";
  //     const location = employee.checkinLocation || "Location Not Found";
  
  //     worksheet.addRow({
  //       employeeId: employee.employeeId,
  //       firstName: `${employee.firstName} ${employee.lastName}`,
  //       date: employee.date,
  //       checkIn: checkIn,
  //       checkOut: checkOut,
  //       checkinLocation: location,
  //       status: employee.date === todayDate ? "Present" : "Absent",
  //     });
  //   });
  
  //   worksheet.getRow(1).font = { bold: true, color: "green" };
  //   worksheet.getRow(1).fill = {
  //     type: "pattern",
  //     pattern: "solid",
  //     fgColor: { argb: "FF0000FF" },
  //   };
  
  //   worksheet.eachRow((row, rowNumber) => {
  //     if (rowNumber > 1) {
  //       const statusCell = row.getCell(7);
  //       if (statusCell.value === "Present") {
  //         statusCell.fill = {
  //           type: "pattern",
  //           pattern: "solid",
  //           fgColor: { argb: "FF00FF00" },
  //         };
  //       } else {
  //         statusCell.fill = {
  //           type: "pattern",
  //           pattern: "solid",
  //           fgColor: { argb: "FFFF0000" },
  //         };
  //       }
  //     }
  //   });
  
  //   const buffer = await workbook.xlsx.writeBuffer();
  //   saveAs(new Blob([buffer]), "Employee_Attendance.xlsx");
  // };

  const handleExport = async () => {
    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Attendance Data");

    // Define worksheet columns
    worksheet.columns = [
        { header: "Employee ID", key: "employeeId", width: 15 },
        { header: "Employee Name", key: "firstName", width: 15 },
        { header: "Date", key: "date", width: 15 },
        { header: "Punch In Time", key: "checkIn", width: 20 },
        { header: "Punch Out Time", key: "checkOut", width: 20 },
        { header: "Location", key: "checkinLocation", width: 30 },
        { header: "Status", key: "status", width: 15 },
    ];

    // Add rows to the worksheet
    attendance.forEach((employee) => {
        const checkIn = employee.checkIn || "Not Punched in Yet";
        const checkOut = employee.checkOut || "Not Punched out Yet";
        const location = employee.checkinLocation || "Location Not Found";

        worksheet.addRow({
            employeeId: employee.employeeId,
            firstName: `${employee.firstName} ${employee.lastName}`,
            date: employee.date,
            checkIn: checkIn,
            checkOut: checkOut,
            checkinLocation: location,
            status: employee.date === todayDate ? "Present" : "Absent",
        });
    });

    // Style each header cell
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
        cell.font = { bold: true, }; // Bold font with green text
        cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "87CEEB" }, // Blue background for header cells
        };
        cell.alignment = { horizontal: "center", vertical: "center" }; // Center align
    });

    // Style each status cell based on its value
    worksheet.eachRow((row, rowNumber) => {
        if (rowNumber > 1) { // Skip the header row
            const statusCell = row.getCell(7); // Status column
            if (statusCell.value === "Present") {
                statusCell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "FF00FF00" }, // Green for "Present"
                };
            } else {
                statusCell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "FFFF0000" }, // Red for "Absent"
                };
            }
        }
    });

    // Write the Excel file and trigger download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Employee_Attendance.xlsx";
    link.click();
};

  


  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // Paginate attendance
  // const paginatedAttendance = attendance.slice(
  //   page * rowsPerPage,
  //   page * rowsPerPage + rowsPerPage
  // );


 

  return (
    <Container elevation={2}>
      <Title variant="h5">Employees Attendance Management</Title>
      <SignedInSection>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 1,
          }}
        >
          {/* <Typography variant="h4" ml={3} mt={3}>
            Employees Attendance:
          </Typography> */}
          <FormControl fullWidth variant="outlined" style={{ width: '280px' ,marginLeft:'15px'}}>
            <InputLabel>Date Range</InputLabel>
            <Select value={dateRange} onChange={handleDateRangeChange} label="Date Range">
                          <MenuItem value="today">Today</MenuItem>
                          <MenuItem value="yesterday">Yesterday</MenuItem>
                          <MenuItem value="lastWeek">Last Week</MenuItem>
                          <MenuItem value="CurrentMonth">Current Month</MenuItem>
                          <MenuItem value="lastMonth">Last Month</MenuItem>
                          <MenuItem value="customDate">Custom Date</MenuItem>
                          
            </Select>
          </FormControl>
          
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#7e31ce",
              marginRight:'20px',
              color: "#fff",
              "&:hover": { backgroundColor: "#7e31ce" },

            }}
            onClick={handleExport}
          >
            <ScreenShareOutlinedIcon sx={{ marginRight: 1 }} />{" "}
            {/* Insert custom Excel icon */}
            Export
          </Button>
        </Box>

        <Box >
          <CardContent>

          <GlobalStyles
          styles={{
            '.custom-sidebar-scrollbar': {
              scrollbarWidth: 'thin', // For Firefox
              scrollbarColor: 'grey #e0e0e0',
            },
            '.custom-sidebar-scrollbar::-webkit-scrollbar': {
              width: '6px', // For WebKit-based browsers
            },
            '.custom-sidebar-scrollbar::-webkit-scrollbar-track': {
              background: '#f1f1f1', // Track color
            },
            '.custom-sidebar-scrollbar::-webkit-scrollbar-thumb': {
              backgroundColor: 'grey', // Thumb color
              borderRadius: '10px',
            },
            '.custom-sidebar-scrollbar::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#555',
            },
          }}
          />

            <TableContainer component={Paper} style={{ maxHeight: '500px', overflowY: 'auto' }} className="custom-sidebar-scrollbar">
              <Table stickyHeader>
                <TableHead className="tableHeader">
                  <TableRow>
                  <TableHeaderCell style={{  backgroundColor:'#529CAD'}}>S.No</TableHeaderCell>
                    <TableHeaderCell style={{  backgroundColor:'#529CAD'}}>Thumbnail</TableHeaderCell>
                    <TableHeaderCell style={{  backgroundColor:'#529CAD'}}>Employee Name</TableHeaderCell>
                    <TableHeaderCell style={{  backgroundColor:'#529CAD'}}>Date</TableHeaderCell>
                    <TableHeaderCell style={{  backgroundColor:'#529CAD'}}>Punch In Time</TableHeaderCell>
                    <TableHeaderCell style={{  backgroundColor:'#529CAD'}}>Punch Out Time</TableHeaderCell>
                    <TableHeaderCell style={{  backgroundColor:'#529CAD'}}>Location</TableHeaderCell>
                    <TableHeaderCell style={{  backgroundColor:'#529CAD'}}>Status</TableHeaderCell>
                    <TableHeaderCell style={{  backgroundColor:'#529CAD'}}>Action</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {loader ? (
                                                <TableRow>
                                                  <TableCell colSpan={9} style={{ textAlign: "center", padding: "20px",fontSize:'15px' }}>
                                                    Loading Data...
                                                  </TableCell>
                                                </TableRow>
                                              ) : attendance.length === 0 ? (
                                                <TableRow>
                                                  <TableCell colSpan={9} style={{ textAlign: "center", padding: "20px" }}>
                                                    No data available
                                                  </TableCell>
                                                </TableRow>
                                              ) : (
                      attendance.map((employee,index) => (
                      <TableRow key={employee.id}>
                         <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "15px",
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {index+1}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            border: "1px solid black",
                            textAlign: "center",
                            fontWeight: "bold",
                            cursor:'pointer',
                          }}
                        >
                          <Avatar
                            alt={employee.firstName}
                            style={{ marginLeft: "25%", marginBottom: "10px" }}
                            src={employee.checkInPic}
                            onClick={() => handleOpenModal(employee)}
                          />
                          {employee.employeeId}
                        </TableCell>

                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "15px",
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {employee.firstName} {employee.lastName}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "15px",
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {employee.date}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "15px",
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {/* {(employee.checkIn)} */}
                          {(employee.checkIn) || 'Not Punched in Yet'}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "15px",
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          {/* {(employee.checkOut)} */}
                          {(employee.checkOut) || 'Not Punched out Yet'}
                        </TableCell>

{/* 
                        <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "15px",
                            textAlign: "center",
                            border: "1px solid black",
                            padding: 0,  // Remove padding from the cell
                            verticalAlign: "top",  // Ensure content is aligned to the top
                            width:'20%'
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            width="100%" // Ensure the box takes up the full width
                          
                          >
                            
                            
                            <Box
                              style={{
                                width: "100%",
                                maxHeight: "50px", // Set a max height for the check-in section
                                overflowY: "auto", // Enable vertical scrolling if content overflows
                                padding: "8px 0",
                              }}
                            >
                              <Typography variant="body2" style={{ fontSize: "15px" }}>
                                {truncateString(employee.checkinLocation, 300)}
                              </Typography>
                            </Box>

                            
                            <Box
                              component="hr"
                              sx={{
                                width: "100%",
                                border: "none",           // Remove default border
                                height: "1px",            // Set line height to 1px for thinness
                                backgroundColor: "black", // Set the line color to black
                                marginTop: 1.5,                // Remove any margin
                              }}
                            />

                          
                            <Box
                              style={{
                                width: "100%",
                                maxHeight: "50px", // Set a max height for the check-out section
                                overflowY: "auto", // Enable vertical scrolling if content overflows
                                padding: "8px 0",
                              }}
                            >
                              <Typography variant="body2" style={{ fontSize: "15px" }}>
                                {truncateString1(employee.checkoutLocation, 300)}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell> */}

                                                  <TableCell
                          className="tableCell"
                          style={{
                            color: "black",
                            fontSize: "15px",
                            textAlign: "center",
                            border: "1px solid black",
                            padding: 0,  // Remove padding from the cell
                            verticalAlign: "top",  // Ensure content is aligned to the top
                            width: '30%'
                          }}
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            width="100%" // Ensure the box takes up the full width
                          >
                            {/* Check-in location */}
                            <Box
                              className="custom-sidebar-scrollbar" // Add the custom scrollbar class
                              style={{
                                width: "100%",
                                maxHeight: "50px", // Set a max height for the check-in section
                                overflowY: "auto", // Enable vertical scrolling if content overflows
                                padding: "8px 0",
                              }}
                            >
                              <Typography variant="body2" style={{ fontSize: "15px" }}>
                                {truncateString(employee.checkinLocation, 300)}
                              </Typography>
                            </Box>
                        
                            {/* Divider Line */}
                            <Box
                              component="hr"
                              sx={{
                                width: "100%",
                                border: "none", // Remove default border
                                height: "1px", // Set line height to 1px for thinness
                                backgroundColor: "black", // Set the line color to black
                                marginTop: 1.5, // Remove any margin
                              }}
                            />
                        
                            {/* Check-out location */}
                            <Box
                              className="custom-sidebar-scrollbar" // Add the custom scrollbar class
                              style={{
                                width: "100%",
                                maxHeight: "50px", // Set a max height for the check-out section
                                overflowY: "auto", // Enable vertical scrolling if content overflows
                                padding: "8px 0",
                              }}
                            >
                              <Typography variant="body2" style={{ fontSize: "15px" }}>
                                {truncateString1(employee.checkoutLocation, 300)}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>

                        <TableCell
                          className="tableCell"
                          style={{
                            color: "green",
                            fontSize: "17px",
                            textAlign: "center",
                            fontWeight: "bold",
                            border: "1px solid black",
                          }}
                        >
                          {employee.date === todayDate ? "Present" : "Absent"}
                        </TableCell>
                        <TableCell
                          className="tableCell"
                          style={{ border: "1px solid black" }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "#7e31ce",
                                color: "#fff",
                                "&:hover": { backgroundColor: "#7e31ce" },
                                marginRight: "10px",
                              }}
                              onClick={() => handleClickOpen(employee)}
                            >
                              Attendance
                            </Button>

                            
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
           
          </CardContent>
        </Box>
      </SignedInSection>

                  {/* Dialo for start date and end date selection  */}

                  <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
  <DialogTitle sx={{fontWeight:'bold',fontSize:'15px'}}>Select Custom Date Range</DialogTitle>
  <DialogContent>
    <div style={{ display: "flex", gap: "16px" ,marginTop:'10px'}}>
      <TextField
        label="Start Date"
        type="date"
        InputLabelProps={{ shrink: true }}
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        fullWidth
      />
      <TextField
        label="End Date"
        type="date"
        InputLabelProps={{ shrink: true }}
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        fullWidth
      />
    </div>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenDialog(false)} variant="outlined" sx={{width:'5px'}}>Cancel</Button>
    <Button
      onClick={() => {
        if (startDate && endDate) {
          handleGetButtonClick();
        } else {
          alert("Please select both Start Date and End Date.");
        }
      }}
    sx={{backgroundColor: "#7e31ce",color: "#fff", "&:hover": { backgroundColor: "#7e31ce" }}}
      variant="outlined"
    >
      Get
    </Button>
  </DialogActions>
</Dialog>




<Dialog open={open} onClose={handleCloseModal} maxWidth="md" fullWidth>
  <DialogTitle>
    {/* <strong style={{ fontSize: '20px' }}>Punch Details</strong> */}
    <strong style={{ fontSize: '20px' }}>Punch Details - 
      
    <strong style={{ color:'#7e31ce' }}> {selectedEmployee?.firstName} {selectedEmployee?.lastName} </strong>
    </strong>
    <IconButton
  aria-label="close"
  onClick={handleCloseModal}
  sx={{
    position: "absolute",
    right: 8,
    top: 8,
    color: "black", // Change the color to your desired value
  }}
>
  <CloseIcon />
</IconButton>

  </DialogTitle>
 
  <DialogContent >
    {selectedEmployee && (
      <Box
      
        gap={2}
      >
         {/* <Divider sx={{ width: "100%", marginY: 2 }} /> */}
        {/* Punch In Details */}
        <Card sx={{ maxWidth: 900 }}>
        <strong style={{ fontSize: '15px',padding:'20px',color:'#0c8091' }}>Punch In Details</strong>
          <CardContent>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={2}
            >
              <Avatar
                alt="Punch In Photo"
                src={selectedEmployee.checkInPic}
                sx={{ width: 150, height: 150 }}
              />
              <Box>
                <Typography variant="h6" style={{ fontWeight: 'bold',paddingLeft:'25px',color:'#7e31ce' }}>Punch In Time: </Typography>
                <Typography variant="h6" style={{fontWeight:'bold',paddingLeft:'25px',color:'Green',paddingTop:5}}>
                {(selectedEmployee.checkIn) || 'N/A'}
                </Typography>
                <Typography variant="h6" style={{fontWeight:'bold',paddingLeft:'25px',color:'#7e31ce'}} mt={2}>
                  Punch In Address:
                </Typography>
                <Typography style={{paddingLeft:'25px',paddingTop:5}}>
                  {truncateString(selectedEmployee.checkinLocation, 300)}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>

        {/* Divider */}
        <Divider sx={{ width: "100%", marginY: 2 }} />

        {/* Punch Out Details */}
        <Card sx={{ maxWidth: 900, boxShadow: 1 }}>
        <strong style={{ fontSize: '15px',padding:'20px',color:'#0c8091' }}>Punch Out Details</strong>
          <CardContent>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={2}
            >
              <Avatar
                alt="Punch Out Photo"
                src={selectedEmployee.checkOutPic}
                sx={{ width: 150, height: 150 }}
              />
              <Box>
                <Typography variant="h6" style={{ fontWeight: 'bold',paddingLeft:'25px',color:'#7e31ce' }}>Punch Out Time: </Typography>
                <Typography variant="h6" style={{fontWeight:'bold',paddingLeft:'25px',color:'Green',paddingTop:5}}>
                {(selectedEmployee.checkOut) || 'N/A'}
                </Typography>
                <Typography variant="h6" style={{fontWeight:'bold',paddingLeft:'25px',color:'#7e31ce'}} mt={2}>
                  Punch Out Address:
                </Typography>
                <Typography style={{paddingLeft:'25px',paddingTop:5}}>
                  {truncateString(selectedEmployee.checkoutLocation, 300)}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    )}
  </DialogContent>
  
</Dialog>

    </Container>
  );
};

export default EmployeeAttendance;