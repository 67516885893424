
// companyContext.js
import React, { createContext, useContext, useState } from 'react';

const CompanyContext = createContext();

export const CompanyProvider = ({ children }) => {
  const [companyInfo, setCompanyInfo] = useState({
    companyLogo: '',
    companyName: '',
  });

  return (
    <CompanyContext.Provider value={{ companyInfo, setCompanyInfo }}>
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompany = () => useContext(CompanyContext);



